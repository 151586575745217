<template>
    <div>
        <div v-if="examId != null">
            <div class="row mb-3">

                <div class="col-md-10 d-flex align-items-center">
                    <b-button variant="outline-secondary" class="btn-sm mr-3" @click="backPage"><i
                            class="ri-arrow-left-line" style="font-size: 30px;"></i>
                    </b-button>
                    <span class="exam mr-2">{{ $t('exams') }}</span>|<span class="course mx-2">
                        {{ courseName }}
                    </span>-<span class="exam-type ml-2">
                        {{ examTypeText }} {{ exam_date ? `- ${this.$moment(exam_date).format("DD/MM/YYYY")}` : '' }}
                    </span>
                </div>
                <div class="col-md-2 d-flex justify-content-end">
                    <b-button v-if="numberOfExams > datatable.rows.length && !noteEntryStatus" class="mt-3 mt-md-0"
                        variant="success" @click="addExams"><i class="ri-add-line mr-1"></i>
                        {{ $t('new') }}
                    </b-button>
                </div>
            </div>
        </div>
        <div v-show="!noteEntryStatus">
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :title="null" :show-paginate="false" :queryParams="datatable.queryParams" :lineNumbers="false"
                v-show="datatable.showTable">

            </datatable>
            <CommonModal ref="createForm" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    {{ $t('create') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form2 v-if="formProgress == 'create'" :examDefinitionId="examId" @successForm="successForm" />
                </template>
            </CommonModal>
            <CommonModal ref="updateForm" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    {{ $t('update') }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateForm2 v-if="formProgress == 'update'" :examDefinitionId="examId" :formId="formId"
                        @successUpdateForm="successUpdateForm" />
                </template>
            </CommonModal>
        </div>
        <div v-show="noteEntryStatus">
            <b-table :empty-text="$t('no_result')" bordered striped responsive :items="students" :fields="fieldNote"
                show-empty class="mb-0 table-dropdown border rounded"
                style="height: calc(100vh - 250px); min-height: 300px; overflow-y: scroll">
                <template #cell(score)="data">
                    <b-form-input :disabled="is_finalized" type="number" min="0" max="100" v-check-min-max-value
                        class="form-control-sm" :value="data.item.score" @keyup="handleInput(data.index, $event)"
                        @keyup.down="handleInput(data.index, $event)" :state="errors[data.index]?.status ? null : false"
                        @keyup.up="handleInput(data.index, $event)"></b-form-input>
                    <b-form-invalid-feedback v-if="!errors[data.index]?.status" v-html="errors[data.index]?.text" />
                </template>
                <template #cell(attended)="row">
                    <b-form-checkbox :disabled="is_finalized" @change="attendedChange(row.index, $event)" :value="1"
                        :unchecked-value="0" v-model="row.item.attended">
                    </b-form-checkbox>
                </template>
            </b-table>
            <div class="mt-3">
                <b-button variant="outline-secondary" class="float-left px-4" @click="print">
                    {{ $t('print') }}
                </b-button>
                <button class="btn btn-primary float-right px-4" v-if="!is_finalized" @click="sendNote">
                    {{ $t('save') }}
                </button>
                <button class="btn btn-success float-right px-4 mr-5" v-if="!is_finalized" @click="publishNote">{{
                    $t('publish') }}</button>
            </div>
        </div>
    </div>
</template>
<script >
import CreateForm2 from "@/modules/examDefinitions/pages/ExamsTable/CreateForm2.vue";
import UpdateForm2 from "@/modules/examDefinitions/pages/ExamsTable/UpdateForm2.vue";
import ExamRequest from "@/services/ExamRequest";
import moment from "moment";
import qs from "qs";
import ExamDefinationService from "@/services/ExamDefinationService";
import ExamService from "@/services/ExamService";

export default {
    name: "ExamDefinitionsExamsTable",
    components: { CreateForm2, UpdateForm2 },
    props: {
        examId: {
            type: Number
        },
        examTypeText: {
            type: String
        },
        courseName: {
            type: String
        },
        section_id: {
            type: Number
        },
        numberOfExams: {
            type: Number
        },
        examType: {
            type: Number
        },
    },
    data() {
        return {
            showExam: false,
            form: {},
            noteEntryStatus: false,
            exam_id: null,
            exam_date: null,
            is_finalized: false,
            published_at : null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('entry_note'),
                                class: 'ri-arrow-right-line',
                                callback: (row) => {
                                    this.noteEntry(row);
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'city_update',
                                callback: (row) => {
                                    this.updateExams(row.id);
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: 'city_delete',
                                callback: (row) => {
                                    this.formDelete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('date'),
                        field: 'date',
                        sortable: false,
                        formatFn: (value, row) => {
                            return this.$moment(row.date).format("DD/MM/YYYY")
                        }
                    },
                    {
                        label: this.$t('rank'),
                        field: 'rank',
                        sortable: false,
                    },
                    {
                        label: this.$t('publish_at'),
                        field: 'published_at',
                        sortable: false,
                        formatFn: (value, row) => {
                            return !row.published_at ? "-" : this.$moment(row.published_at).format("DD/MM/YYYY")
                        }
                    },

                    // {
                    //     label: '#',
                    //     field: 'btn',
                    //     sortable: false,
                    // },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: true,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            errors: [],
            students: [],
            fieldNote: [
                { key: 'student_number', label: this.toUpperCase('student_number'), sortable: true },
                { key: 'name', label: this.toUpperCase('name'), sortable: true },
                {
                    label: this.$t('program_name'),
                    key: 'program_name',
                },
                { key: 'score', label: this.toUpperCase('exam_score'), sortable: true },
                { key: 'attended', label: this.toUpperCase('attended'), sortable: true }
            ],
            formProgress: null,
            formId: null,
            masks: {
                input: "YYYY-MM-DD",
            },
        }
    },
    watch: {
        examId: {
            handler(newValue) {
                this.getRows(newValue)
            }
        }
    },
    created() {
        this.getRows(this.examId)
    },
    methods: {
        print() {
            const headers = [this.$t('student_number'), this.$t('full_name'), this.$t('program_name'), this.$t('score'), this.$t('attended')]
            const columns = this.students.map(s => Object.values({
                student_number: s.student_number,
                name: s.name,
                program_name: s.program_name,
                score: s.score,
                attended: s.attended ? this.$t('yes') : this.$t('no'),
            }))
            const headerText = `${this.courseName} - ${this.examTypeText} - ${this.$moment(this.exam_date).format("DD/MM/YYYY")} -
            ${this.is_finalized ? this.$t('published') + " " + this.$moment(this.published_at).format("DD/MM/YYYY") : ''}`

            this.printTable({ headers, columns, headerText, id: "print-table" })
        },
        async publishNote() {
            this.errors = []
            let publishReady = true
            this.students.forEach((item) => {
                const errData = {
                    status: true,
                    text: ""
                }
                if (!item.score && item.score !== 0 && item.attended) {
                    errData.status = false
                    errData.text = this.$t('note_entry_error')
                    publishReady = false
                } else if (!item.attended && ((item.score || item.score === 0))) {
                    errData.status = false
                    errData.text = this.$t('note_entry_error1')
                    publishReady = false
                }
                this.errors.push(errData)
            })

            if (publishReady) {
                try {
                    await this.sendNote()
                    const response = await ExamDefinationService.examPublish(this.exam_id)
                    this.$toast.success(this.$t("api." + response.data.message));

                    if (response.data.success) {
                        this.updateExamDefinition(this.examId)
                    }
                } catch (err) {
                    this.showErrors(err)
                }
            }
        },
        attendedChange(index, event) {
            this.students[index].attended = event
        },
        async sendNote() {
            const data = {
                exam_id: this.exam_id,
                scores: this.students.map(item => ({ student_program_id: item.student_program_id, score: item.score == null ? null : item.score, attended: item.attended }))
            }
            const response = await ExamDefinationService.examScoreSave(data).catch(err => {
                this.showErrors(err)
            })

            if (response.data.success) {
                this.errorsStateDefault()
            }
            this.$toast.success(this.$t("api." + response.data.message));
        },
        errorsStateDefault() {
            this.errors = this.students.map(() => ({ status: true, text: "" }))
        },
        async noteEntry(row) {
            const studentRes = await ExamDefinationService.getStudents(this.section_id)
            const examRes = await ExamDefinationService.examScores(row.id)
            this.exam_id = row.id
            this.exam_date = row.date
            this.is_finalized = row.is_finalized
            this.published_at = row.published_at
            if (studentRes.data.success) {
                this.students = studentRes.data.data.map(item => ({ program_name: item.program_name, student_program_id: item.student_program_id, student_number: item.student_number, name: `${item.name} ${item.surname}`, score: null, attended: 1 }))
                this.errorsStateDefault()
            }

            if (examRes.data.success) {
                const keys = Object.keys(examRes.data.data)
                keys.forEach(item => {
                    const studentIndex = this.students.findIndex(a => a.student_program_id == item)
                    if (studentIndex > -1) {
                        this.students[studentIndex].score = examRes.data.data[item][0].score
                        this.students[studentIndex].attended = examRes.data.data[item][0].attended ? 1 : 0
                    }
                })
            }

            this.noteEntryStatus = true
        },
        formClear() {
            this.formProgress = null
            this.formId = null
            this.form = {
                date: null,
                rank: null

            }
        },
        changeDate(item) {
            this.form.date = moment(item).format('YYYY-MM-DD')
        },
        handleInput(index, input) {
            if (!input.target.value && input.target.value !== 0) {
                this.students[index].score = null
                return
            }
            this.students[index].score = Number(input.target.value)
        },
        updateExamDefinition(value) {
            const config = {
                params: {
                    filter: {
                        exam_definition_id: value
                    },
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };
            ExamRequest.getAll(config)
                .then(response => {
                    const find = response.data.data.filter(item => item.id === this.exam_id)
                    if (find?.length > 0) {
                        this.is_finalized = find[0].is_finalized
                    }
                }).catch(err => {
                    this.showErrors(err)
                })
        },
        getRows(value) {
            const config = {
                params: {
                    filter: {
                        exam_definition_id: value
                    },
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };
            ExamService.getAll(config)
                .then(response => {
                    this.datatable.rows = response.data.data.map(item => ({ ...item, btn: null }))
                }).catch(err => {
                    this.showErrors(err)
                })
        },

        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        ExamRequest.deletes(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows(this.examId);
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
        addExams() {
            this.formProgress = 'create'
            this.$refs.createForm.$refs.commonModal.show();
        },
        successForm() {
            this.formClear()
            this.$refs.createForm.$refs.commonModal.hide();
            this.getRows(this.examId)
        },
        updateExams(id) {
            this.formId = id
            this.formProgress = 'update'
            this.$refs.updateForm.$refs.commonModal.show();
        },
        successUpdateForm() {
            this.formClear()
            this.$refs.updateForm.$refs.commonModal.hide();
            this.getRows(this.examId)
        },
        backPage() {
            if (this.noteEntryStatus) {
                this.noteEntryStatus = !this.noteEntryStatus
                this.exam_date = null
                this.getRows(this.examId)
                return
            }

            this.exam_id = null
            this.$emit('tableStatusUpdate', 'examDefinition')
        }

    },
}
</script>
<style>
.table-custom-btn {
    width: 100%;
    min-height: 40px;
}

.exam {
    font-size: 20px;
    color: black;
}

.course {
    font-size: 16px;
}

.exam-type {
    font-size: 16px;
}

.icon {
    font-size: 16px;
}
</style>
