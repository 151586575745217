<template>
    <div>
        <ValidationObserver ref="form">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('date')" class="position-relative">
                            <v-date-picker v-model="form.date"
                                           :locale="'en'"
                                           :masks="masks"
                                           @input="changeDate"
                                           :validateError="errors[0]"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <b-input-group>
                                        <b-form-input :readonly="true" :value="inputValue"
                                                      v-on="inputEvents"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                <i class="ri-calendar-line"></i></b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>

                                    </b-input-group>

                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input type="number" v-model="form.rank">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 mt-3 d-flex">
                    <b-button
                        @click="createForm"
                        type="button"
                        variant="primary"
                        class="btn-lg mr-2"
                    >
                        {{ $t("save") | toUpperCase }}
                    </b-button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
import moment from "moment/moment";
import ExamService from "@/services/ExamService";

export  default {
    props:{
        examDefinitionId:{
            type:Number
        },
        sectionId: {
            type: Number
        },
    },
    data(){
        return{
            masks: {
                input: "YYYY-MM-DD",
            },
            form:{}
        }
    },

    methods:{
        changeDate(item){
            this.form.date=moment(item).format('YYYY-MM-DD')
        },
        async createForm(){
            let isValid = await this.$refs.form.validate()
            if(isValid){
                console.log(this.form,this.sectionId)
                let formData = {
                    exam_definition_id:this.examDefinitionId,
                    date:this.form.date,
                    rank:this.form.rank,
                }
                ExamService.store(formData)
                    .then(response=>{
                        this.$toast.success(response.data.message)
                         this.$emit('successForm',true)
                    }).catch(err=>{
                    this.showErrors(err)
                })
            }
        }

    }
}
</script>
